<script setup></script>

<template>
  <div>
    <AuthenticatedLayout>
      <div
        class="bg-[#F7F7F7] dark:bg-gray-900 h-[92vh] overflow-y-auto"
      >
        <div>
          <slot />
        </div>
      </div>
    </AuthenticatedLayout>
  </div>
</template>
